import React, {useEffect} from 'react';
import ReactGA from 'react-ga'
import './App.css';

const gaId = "UA-36738577-1";

function App() {
  useEffect(() => {
    ReactGA.initialize(gaId);
    ReactGA.pageview(window.location.pathname);
  })

  return (
    <main>
      <h1>David Alson</h1>
      {/* <h2>Engineering Leader</h2> */}
      <ul>
        <a href="https://www.linkedin.com/in/alson/">LinkedIn</a>
      </ul>
    </main>
  );
}

export default App;
